import React, { useEffect, useState } from "react";
import { useMainContext } from "../../../../Context";
import CheckExist from "../../../CheckExist";

const SelectImage = ({ element, form, setForm }) => {
  const { get } = useMainContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    setLoading(true);
    const { data } = await get("/frame_images");
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <CheckExist
      state={
        element.exist ? form[element.exist.key] == element.exist.value : true
      }
    >
      {loading ? (
        <>
          <div className="my-3 d-flex justify-content-center align-items-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        </>
      ) : (
        <div className="row my-2">
          {data.map(img => {
            return (
              <div
                key={img.id}
                className={`col ${
                  form[element.key] == img.id ? "border border-2  rounded" : ""
                }`}
                onClick={() => {
                  setForm({ ...form, [element.key]: img.id });
                }}
              >
                <img
                  src={img.svg.replace("svg", "jpg")}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "contain",
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </CheckExist>
  );
};

export default SelectImage;
