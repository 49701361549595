import React, { useEffect, useState } from "react";
import { humanFileSize } from "../../../../Utils/functions";

const MultiImageUploader = ({ element, form, setForm, edit = false }) => {
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setForm({ ...form, [element.key]: undefined });

      return;
    }
    setForm({ ...form, [element.key]: e.target.files });
  };

  const renderItem = () => {
    if (edit && typeof form[element.key] == "string") {
      return (
        <div className="dz-preview dz-preview-multiple m-0 d-flex flex-column">
          <div className="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger dz-image-preview">
            <img
              className="dz-image"
              alt="29.png"
              data-dz-thumbnail="data-dz-thumbnail"
              src={form[element.key]}
              style={{
                borderRadius: "0.25rem",
                height: "2.5rem",
                width: "2.5rem",
                objectFit: "cover",
                objectFit: "cover",
                marginLeft: "1rem",
              }}
            />
            <div className="flex-1 d-flex flex-between-center">
              <div>
                <h6 data-dz-name="data-dz-name">{form[element.key]}</h6>
                <div className="d-flex align-items-center">
                  {/* <p
                    className="mb-0 fs--1 text-400 lh-1"
                    data-dz-size="data-dz-size"
                  >
                    {humanFileSize(form[element.key].size)}
                  </p> */}
                </div>
                <span
                  className="fs--2 text-danger"
                  data-dz-errormessage="data-dz-errormessage"
                />
              </div>
              <div className="dropdown font-sans-serif">
                <button
                  className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="svg-inline--fa fa-ellipsis-h fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="ellipsis-h"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                    />
                  </svg>
                  {/* <span class="fas fa-ellipsis-h"></span> Font Awesome fontawesome.com */}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end border py-2"
                  style={{}}
                >
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setForm({ ...form, [element.key]: undefined });
                    }}
                  >
                    حذف
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (typeof form[element.key] == "object") {
      return (
        <div className="dz-preview dz-preview-multiple m-0 d-flex flex-column">
          {Array.from(form[element.key]).map(item => {
            return (
              <>
                <div className="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger dz-image-preview">
                  <img
                    className="dz-image"
                    alt="29.png"
                    data-dz-thumbnail="data-dz-thumbnail"
                    src={URL.createObjectURL(item)}
                    style={{
                      borderRadius: "0.25rem",
                      height: "2.5rem",
                      width: "2.5rem",
                      objectFit: "cover",
                      objectFit: "cover",
                      marginLeft: "1rem",
                    }}
                  />
                  <div className="flex-1 d-flex flex-between-center">
                    <div>
                      <h6 data-dz-name="data-dz-name">{item.name}</h6>
                      <div className="d-flex align-items-center">
                        <p
                          className="mb-0 fs--1 text-400 lh-1"
                          data-dz-size="data-dz-size"
                        >
                          {humanFileSize(item.size)}
                        </p>
                      </div>
                      <span
                        className="fs--2 text-danger"
                        data-dz-errormessage="data-dz-errormessage"
                      />
                    </div>
                    <div className="dropdown font-sans-serif">
                      <button
                        className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          className="svg-inline--fa fa-ellipsis-h fa-w-16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="ellipsis-h"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg
                        >
                          <path
                            fill="currentColor"
                            d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                          />
                        </svg>
                        {/* <span class="fas fa-ellipsis-h"></span> Font Awesome fontawesome.com */}
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-end border py-2"
                        style={{}}
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            setForm({ ...form, [element.key]: undefined });
                          }}
                        >
                          حذف
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      );
    } else if (
      edit &&
      Array.isArray(form[element.key]) &&
      form[element.key].length > 0
    ) {
      return (
        <div className="dz-preview dz-preview-multiple m-0 d-flex flex-column">
          <div className="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger dz-image-preview">
            <img
              className="dz-image"
              alt="29.png"
              data-dz-thumbnail="data-dz-thumbnail"
              src={form[element.key][0].url}
              style={{
                borderRadius: "0.25rem",
                height: "2.5rem",
                width: "2.5rem",
                objectFit: "cover",
                objectFit: "cover",
                marginLeft: "1rem",
              }}
            />
            <div className="flex-1 d-flex flex-between-center">
              <div>
                <h6 data-dz-name="data-dz-name">{form[element.key].name}</h6>
                <div className="d-flex align-items-center">
                  <p
                    className="mb-0 fs--1 text-400 lh-1"
                    data-dz-size="data-dz-size"
                  >
                    {humanFileSize(form[element.key].size)}
                  </p>
                </div>
                <span
                  className="fs--2 text-danger"
                  data-dz-errormessage="data-dz-errormessage"
                />
              </div>
              <div className="dropdown font-sans-serif">
                <button
                  className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="svg-inline--fa fa-ellipsis-h fa-w-16"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="ellipsis-h"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                    />
                  </svg>
                  {/* <span class="fas fa-ellipsis-h"></span> Font Awesome fontawesome.com */}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end border py-2"
                  style={{}}
                >
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setForm({ ...form, [element.key]: undefined });
                    }}
                  >
                    حذف
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };
  return (
    <div
      className="dropzone dropzone-single p-0 dz-clickable my-4"
      data-dropzone="data-dropzone"
      data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'
    >
      <div className="dz-preview dz-preview-single" />
      <input
        id="fileUploader"
        type={"file"}
        className="d-none"
        accept="image/*"
        multiple
        onChange={onSelectFile}
      />
      <label
        className="dz-message d-block mb-3 "
        htmlFor="fileUploader"
        style={{ cursor: "pointer" }}
      >
        <div className="dz-message-text text-center">
          <img
            className="me-2"
            src="../../../assets/img/icons/cloud-upload.svg"
            width={25}
            alt
          />
          {element.label}
        </div>
      </label>
      {renderItem()}
    </div>
  );
};

export default MultiImageUploader;
