import React from "react";

import Select from "react-select";
const MultiSelect = ({
  loading,
  data = [],
  element,
  handleValue,
  handleChange,
  disible = false,
}) => {
  if (loading) {
    return (
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    );
  }
  return (
    <div className="form-group my-2">
      <label>{element.label}</label>
      <Select
        isMulti
        onChange={e => {
          handleChange(element.key, e);
        }}
        options={data}
        isDisabled={disible}
        defaultValue={handleValue(element.key) || "Select"}
      />
    </div>
  );
};

export default MultiSelect;
