import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMainContext } from "../../../Context";

export const useSurface = ({ api, key }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setloadingBtn] = useState(false);
  const [reporter, setReporter] = useState({});
  const {
    post,
    state: { user },
  } = useMainContext();
  useEffect(() => {
    getData();
  }, [loadingBtn]);
  const getData = async () => {
    setLoading(true);
    const { data } = await post(api?.route, { [key]: id });
    if (Array.isArray(data)) {
      setData(data);
    } else {
      setData(data);
      setReporter(data.experter);
    }
    setLoading(false);
  };

  const sendData = async report_id => {
    setloadingBtn(report_id);
    await post("/crm/report_delete", { report_id });
    setloadingBtn(false);
  };

  return {
    loading,
    data,
    id,
    sendData,
    loadingBtn,
    user,
    reporter,
  };
};
export const useDetails = ({ api }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { get } = useMainContext();
  const { id } = useParams();

  const getOfferDetails = async () => {
    setLoading(true);
    const { data } = await get(`${api.route}/${id}`);
    setData(data?.data);
    setLoading(false);
  };
  useEffect(() => {
    getOfferDetails();
  }, [id]);

  return { data, loading };
};
export const useChat = () => {
  const [chat, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [owner, setOwner] = useState({});
  const { get } = useMainContext();
  const { id } = useParams();

  const getOfferDetails = async () => {
    setLoading(true);
    const { data } = await get(`/conversations/${id}`);
    setData(data);
    setOwner(data?.docs[0]?.author?._id);
    setLoading(false);
  };
  useEffect(() => {
    getOfferDetails();
  }, [id]);
  const loadMore = async () => {
    setLoadingBtn(true);
    const { data } = await get(
      `/conversations/${id}?page=${chat?.page ? chat?.page + 1 : 1}`
    );
    setData({ ...data, docs: [...chat.docs, ...data.docs] });
    setOwner(data?.docs[0]?.author?._id);
    setLoadingBtn(false);
  };

  return { chat, loading, loadMore, loadingBtn, owner };
};
