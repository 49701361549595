import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { useMainContext } from "../../Context";
import Layout from "../../Layouts";
import { isJson, sortSettings, timeSince } from "../../Utils/functions";
import Dashboard from "../Dashboard";
import Form from "../Form";
import FormV1 from "../Form-V1";
import NotFound from "../NotFound";
import Support from "../Support";
import Surfaces from "../Surfaces";
import Table from "../Table";
const dashboard = {
  label: "Dasboard",
  icon: "fab fa-trello",
  route: "/",
  nested: false,
  access: ["admin", "super-admin"],
  options: [
    {
      route: "/",
      type: "dashboard",
      elements: [],
    },
  ],
};
const profile = {
  label: "profile",
  access: ["admin", "super-admin"],
  icon: "fas fa-user-edit",
  route: "/profile",
  nested: false,
  options: [
    {
      show: true,
      route: "/profile",
      type: "surface",
      surfaceType: "profile",
      access: ["admin", "expert"],
    },
  ],
};
const users = {
  label: "users",
  access: ["admin", "super-admin"],
  nested: true,
  icon: "far fa-user",
  options: [
    {
      route: "/user/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/users",
      },
      box: {
        route: "/user/list",
        title: "list",
      },

      form: [
        {
          key: "name",
          label: "name",
          col: "col-md-6",
        },
        {
          key: "lastName",
          label: "lastName",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "email",
          label: "email",
          col: "col-md-6",
        },
        {
          key: "password",
          label: "password",
          col: "col-md-6",
        },
        {
          key: "mobile",
          label: "mobile",
          col: "col-md-6",
          noNeed: true,
        },
        {
          key: "paymentMethods",
          label: "payment method",
          col: "col-md-6",
        },
        {
          type: "customerAddress",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "address",
          label: "address",
          col: "col-md-9",
        },
        {
          key: "zipCode",
          label: "zipCode",
          col: "col-md-3",
          noNeed: true,
        },
        {
          key: "accountType",
          noNeed: true,
          label: "account type",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "juridical",
              key: "juridical",
            },
            {
              name: "natural",
              key: "natural",
            },
          ],
        },
        {
          key: "taxNumber",
          label: "tax Number",
          exist: {
            key: "accountType",
            value: "juridical",
          },
          col: "col-md-6",
        },
      ],
    },
    {
      route: "/user/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/users",
      },
      actions: [
        {
          type: "show",
          link: "/user/update",
          label: "show profile",
        },
        {
          type: "toggle",
          route: "/toggle/users",
          label: "block user",
          key: "_id",
        },
        {
          type: "delete",
          label: "delete",
          route: "/users",
        },
      ],
      header: [
        {
          label: "id",
          key: "_id",
        },
        {
          label: "first name",
          key: "name",
        },
        {
          label: "last name",
          key: "lastName",
        },
        {
          label: "email",
          key: "email",
        },
        {
          objectKey: "location",
          label: "country",
          key: "country",
          type: "country",
        },
        {
          type: "nested",
          objectKey: "location",
          label: "city",
          key: "city",
        },
        {
          type: "isBlocked",
          label: "status",
          key: "isBlocked",
        },
        {
          type: "date",
          label: "deleted",
          key: "deletedAt",
        },
      ],
    },
    {
      route: "/user/update/:id",
      type: "edit",
      show: false,
      label: "edit user",
      box: {
        title: "list",
        route: "/user/list",
      },
      api: {
        route: "/users",
        show: {
          route: "/users",
          key: "user_id",
        },
      },
      sortInint: (state) => {
        console.log("s", state);
        return {
          ...state,
          ...state.user,
          ...state.user.location,
          followers: state.followers.map((item) => {
            return item.follower;
          }),
          followings: state.followings.map((item) => {
            return item.following;
          }),
          isBlocked: state.user.isBlocked ? "true" : "false",
        };
      },
      form: [
        {
          type: "avatar",
          label: "Avatar",
          key: "avatarAddress",
        },
        {
          key: "name",
          label: "name",
          col: "col-md-6",
        },
        {
          key: "lastName",
          label: "lastName",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "email",
          label: "email",
          col: "col-md-6",
        },
        {
          key: "password",
          label: "password",
          col: "col-md-6",
        },
        {
          key: "mobile",
          label: "mobile",
          col: "col-md-6",
          noNeed: true,
        },
        {
          key: "paymentMethods",
          label: "payment method",
          col: "col-md-6",
        },
        {
          type: "customerAddress",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "address",
          label: "address",
          col: "col-md-9",
        },
        {
          key: "zipCode",
          label: "zipCode",
          col: "col-md-3",
          noNeed: true,
        },
        {
          key: "accountType",
          noNeed: true,
          label: "account type",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "juridical",
              key: "juridical",
            },
            {
              name: "natural",
              key: "natural",
            },
          ],
        },
        {
          key: "taxNumber",
          label: "tax Number",
          exist: {
            key: "accountType",
            value: "juridical",
          },
          col: "col-md-6",
        },
        {
          key: "isBlocked",
          label: "Is Blocked?",
          type: "toggle",
          col: "col-md-6",
        },
        {
          key: "rate",
          label: "Rate",
          disabled: true,
        },
        {
          type: "list",
          key: "blocks",
          title: "Blocked users",
        },
        {
          type: "list",
          key: "followers",
          title: "Followers",
        },
        {
          type: "list",
          key: "followings",
          title: "Following",
        },
      ],
    },
  ],
};
const admins = {
  label: "admins",
  access: ["admin", "super-admin"],
  nested: true,
  icon: "far fa-user",
  options: [
    {
      route: "/admin/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/admins",
      },
      box: {
        route: "/admin/list",
        title: "list",
      },
      form: [
        {
          key: "name",
          label: "name",
          col: "col-md-6",
        },
        {
          key: "email",
          label: "email",
          col: "col-md-6",
        },

        {
          key: "password",
          label: "password",
          col: "col-md-6",
        },
        {
          key: "role",
          access: ["super-admin"],
          noNeed: true,
          label: "account role",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "super admin",
              key: "super-admin",
            },
            {
              name: "admin",
              key: "admin",
            },
          ],
        },
      ],
    },
    {
      route: "/admin/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/admins",
      },
      actions: [
        {
          type: "show",
          link: "/admin/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/admins",
        },
      ],
      header: [
        {
          label: "full name",
          key: "name",
        },
        {
          label: "email",
          key: "email",
        },
        {
          key: "role",
          label: "role",
        },
        {
          type: "date",
          label: "deleted",
          key: "deletedAt",
        },
      ],
    },
    {
      route: "/admin/update/:id",
      type: "edit",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/admin/list",
      },
      api: {
        route: "/admins",
        show: {
          route: "/admins",
          key: "admin_id",
        },
      },
      form: [
        {
          key: "name",
          label: "name",
          col: "col-md-6",
        },
        {
          key: "email",
          label: "email",
          col: "col-md-6",
        },
        {
          key: "role",
          noNeed: true,
          access: ["super-admin"],
          label: "account role",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "super admin",
              key: "super-admin",
            },
            {
              name: "admin",
              key: "admin",
            },
          ],
        },
      ],
    },
  ],
};
const requests = {
  label: "requests",
  access: ["admin", "super-admin"],
  nested: true,
  icon: "fas fa-paperclip",
  options: [
    {
      route: "/request/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/requests",
        needs: ["currency"],
      },
      box: {
        route: "/request/list",
        title: "list",
      },
      form: [
        {
          label: "user email",
          key: "email",
          col: "col-md-7",
        },
        {
          label: "price",
          key: "price",
          col: "col-md-6",
        },
        {
          key: "currency_id",
          label: "currency",
          type: "select",
          need: "currency",
          col: "col-md-6 mt-2",
        },
        {
          type: "customerAddress",
          subKey: "from",
          subLabel: "from",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          type: "customerAddress",
          subKey: "destination",
          subLabel: "destination",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "packageWeight",
          label: "package Weight",
          col: "col-md-6",
        },
        {
          key: "AcceptPackageType",
          noNeed: true,
          label: "Accept Package Type",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "envelope",
              key: "envelope",
            },
            {
              name: "box",
              key: "box",
            },
            {
              name: "others",
              key: "others",
            },
          ],
        },
        {
          type: "textarea",
          key: "description",
          label: "description",
          col: "col-md-12",
          noNeed: true,
        },
      ],
    },
    {
      route: "/request/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/requests",
      },
      actions: [
        {
          type: "show",
          link: "/request/update",
          label: "show profile",
        },
        {
          type: "go",
          link: "/request/full_data",
          label: "show details",
        },
        {
          type: "delete",
          label: "delete",
          route: "/requests",
        },
      ],
      header: [
        {
          objectKey: "user",
          type: "nested",
          label: "user name",
          key: "name",
          col: "col-md-6",
        },
        {
          key: "agreementUser",
          type: "statusAgree",
          label: "Agreement Status",
        },
        {
          objectKey: "user",
          type: "nested",
          label: "user last name",
          key: "lastName",
          col: "col-md-6",
        },
        {
          objectKey: "user",
          type: "nested",
          label: "user email",
          key: "email",
          col: "col-md-6",
        },
        {
          label: "from Country",
          key: "fromCountry",
          type: "countryFrom",
        },
        {
          label: "destination Country",
          key: "destinationCountry",
          type: "countryFrom",
        },
        {
          label: "price",
          key: "price",
        },
        {
          label: "package Weight",
          key: "packageWeight",
        },
        {
          objectKey: "agreementUser",
          type: "nested",
          label: "agreement user name",
          key: "name",
          col: "col-md-6",
        },
        {
          objectKey: "agreementUser",
          type: "nested",
          label: "agreement user email",
          key: "email",
          col: "col-md-6",
        },
        {
          type: "date",
          label: "created At",
          key: "createdAt",
        },
        {
          type: "date",
          label: "deleted ",
          key: "deletedAt",
        },
      ],
    },
    {
      route: "/request/update/:id",
      type: "edit",
      show: false,
      label: "edit request",
      box: {
        title: "list",
        route: "/request/list",
      },
      api: {
        route: "/update/requests",
        needs: ["currency"],

        show: {
          route: "/requests",
          key: "request_id",
        },
      },
      sortInint: (state) => {
        return { ...state };
      },
      form: [
        {
          type: "customerAddress",
          subKey: "from",
          subLabel: "from",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          type: "customerAddress",
          subKey: "destination",
          subLabel: "destination",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "packageWeight",
          label: "package Weight",
          col: "col-md-6",
        },
        {
          key: "AcceptPackageType",
          noNeed: true,
          label: "Accept Package Type",
          col: "col-md-6 mt-2",
          type: "select",
          options: [
            {
              name: "envelope",
              key: "envelope",
            },
            {
              name: "box",
              key: "box",
            },
            {
              name: "anything",
              key: "anything",
            },
          ],
        },
        {
          key: "price",
          label: "Price",
          col: "col-md-6",
        },
        {
          key: "currency_id",
          label: "currency",
          type: "select",
          need: "currency",
          col: "col-md-6 mt-2",
        },

        {
          type: "textarea",
          key: "description",
          label: "description",
          col: "col-md-12",
          noNeed: true,
        },
        {
          type: "showImages",
          key: "",
        },
        {
          type: "link",
          label: "Owner Profile",
          key: "user",
        },
        {
          type: "link",
          label: "Agreed User Profile",
          key: "agreementUser",
        },
      ],
    },
    {
      label: "full data",
      show: false,
      type: "surface",
      surfaceType: "requestData",
      key: "id",
      route: "/request/full_data/:id",
      api: {
        route: "/requests/full_data",
      },
    },
  ],
};
const offers = {
  label: "offers",
  access: ["admin", "super-admin"],
  nested: true,
  icon: "fas fa-paperclip",
  options: [
    {
      route: "/offer/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/offers",
        needs: ["currency"],
      },
      box: {
        route: "/offer/list",
        title: "list",
      },
      need: ["currency"],
      form: [
        {
          label: "user email",
          key: "email",
          col: "col-md-7",
        },
        {
          label: "price",
          key: "price",
          col: "col-md-6",
        },
        {
          key: "currency_id",
          label: "currency",
          type: "select",
          need: "currency",
          col: "col-md-6 mt-2",
        },
        {
          type: "customerAddress",
          subKey: "from",
          subLabel: "from",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          type: "customerAddress",
          subKey: "destination",
          subLabel: "destination",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "packageWeight",
          label: "package Weight",
          col: "col-md-6",
        },
        {
          key: "AcceptPackageType",
          noNeed: true,
          label: "Accept Package Type",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "envelope",
              key: "envelope",
            },
            {
              name: "box",
              key: "box",
            },
            {
              name: "anything",
              key: "anything",
            },
          ],
        },
        {
          type: "textarea",
          key: "description",
          label: "description",
          col: "col-md-12",
          noNeed: true,
        },
        {
          type: "date",
          single: true,
          key: "expireAt",
          label: "expire At",
          col: "col-md-6",
        },
      ],
    },
    {
      route: "/offer/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/offers",
      },
      actions: [
        {
          type: "show",
          link: "/offer/update",
          label: "show profile",
        },
        {
          type: "go",
          link: "/offer/full_data",
          label: "show details",
        },
        {
          type: "delete",
          label: "delete",
          route: "/offers",
        },
      ],
      header: [
        {
          objectKey: "user",
          type: "nested",
          label: "user name",
          key: "name",
          col: "col-md-6",
        },
        {
          key: "agreementUser",
          type: "statusAgree",
          label: "Agreement Status",
        },
        {
          objectKey: "user",
          type: "nested",
          label: "user last name",
          key: "lastName",
          col: "col-md-6",
        },
        {
          objectKey: "user",
          type: "nested",
          label: "user email",
          key: "email",
          col: "col-md-6",
        },
        {
          label: "from Country",
          key: "fromCountry",
          type: "countryFrom",
        },
        {
          label: "destination Country",
          key: "destinationCountry",
          type: "countryFrom",
        },
        {
          label: "price",
          key: "price",
        },
        {
          label: "package Weight",
          key: "packageWeight",
        },
        {
          objectKey: "agreementUser",
          type: "nested",
          label: "agreement user name",
          key: "name",
          col: "col-md-6",
        },
        {
          objectKey: "agreementUser",
          type: "nested",
          label: "agreement user email",
          key: "email",
          col: "col-md-6",
        },
        {
          type: "date",
          label: "created At",
          key: "createdAt",
        },
        {
          type: "date",
          label: "deleted ",
          key: "deletedAt",
        },
      ],
    },
    {
      route: "/offer/update/:id",
      type: "edit",
      show: false,
      label: "edit offer",
      box: {
        title: "list",
        route: "/offer/list",
      },
      api: {
        route: "/offers",
        needs: ["currency"],
        show: {
          route: "/offers",
          key: "offer_id",
          needs: ["currency"],
        },
      },
      form: [
        {
          type: "customerAddress",
          subKey: "from",
          subLabel: "from",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          type: "customerAddress",
          subKey: "destination",
          subLabel: "destination",
          label: "address",
          help: "at least 8 characters",
          noNeed: true,
          col: "col-md-6",
        },
        {
          key: "packageWeight",
          label: "package Weight",
          col: "col-md-6",
        },
        {
          key: "AcceptPackageType",
          noNeed: true,
          label: "Accept Package Type",
          col: "col-md-6 mt-2",
          type: "select",
          options: [
            {
              name: "envelope",
              key: "envelope",
            },
            {
              name: "box",
              key: "box",
            },
            {
              name: "anything",
              key: "anything",
            },
          ],
        },
        {
          key: "price",
          label: "Price",
          col: "col-md-6",
        },
        {
          key: "currency_id",
          label: "currency",
          type: "select",
          need: "currency",
          col: "col-md-6 mt-2",
        },

        {
          type: "textarea",
          key: "description",
          label: "description",
          col: "col-md-12",
          noNeed: true,
        },
        {
          type: "date",
          single: true,
          key: "expireAt",
          label: "expire At",
          col: "col-md-6",
        },

        {
          type: "link",
          label: "Owner Profile",
          key: "user",
        },
        {
          type: "link",
          label: "Agreed User Profile",
          key: "agreementUser",
        },
      ],
    },
    {
      label: "full data",
      show: false,
      type: "surface",
      surfaceType: "offerData",
      key: "id",
      route: "/offer/full_data/:id",
      api: {
        route: "/offers/full_data",
      },
    },
    {
      label: "chat",
      show: false,
      type: "surface",
      surfaceType: "chat",
      key: "id",
      route: "/conversations/chat/:id",
      api: {
        route: "/offer/full_data",
      },
    },
  ],
};
const offersPic = {
  label: "Offer Picture",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: true,
  options: [
    {
      route: "/offer-pic/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/offer-pic",
      },
      box: {
        route: "/offer-pic/list",
        title: "list",
      },
      form: [
        {
          key: "image",
          label: "images",
          type: "images",
        },
      ],
    },
    {
      route: "/offer-pic/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/offer-pic",
      },
      actions: [
        {
          type: "show",
          link: "/offer-pic",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/offer-pic",
        },
      ],
      header: [
        {
          type: "nested",
          objectKey: "created_by",
          label: "created by",
          key: "name",
        },
        {
          label: "address",
          key: "address",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/offer-pic/:id",
      type: "show",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/offer-pic/list",
      },
      api: {
        route: "/offer-pic",
        show: {
          route: "/offer-pic",
          key: "site-content_id",
        },
      },
      form: [
        {
          key: "address",
          label: "images",
          type: "images",
        },
      ],
    },
  ],
};
const siteContent = {
  label: "site Content",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: true,
  options: [
    {
      route: "/site-content/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/site-content",
      },
      box: {
        route: "/site-content/list",
        title: "list",
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-12",
        },
        {
          key: "language",
          label: "language",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "de",
              key: "de",
            },
            {
              name: "en",
              key: "en",
            },
            {
              name: "ru",
              key: "ru",
            },
          ],
        },
        {
          key: "page",
          label: "page",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "about-us",
              key: "about-us",
            },
            {
              name: "policy",
              key: "policy",
            },
          ],
        },
        {
          label: "content",
          key: "content",
          col: "col-md-12",
          type: "editor",
        },
      ],
    },
    {
      route: "/site-content/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/site-content",
      },
      actions: [
        {
          type: "show",
          link: "/site-content/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/site-content",
        },
      ],
      header: [
        {
          label: "page",
          key: "page",
        },
        {
          label: "language",
          key: "language",
        },
        {
          label: "title",
          key: "title",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/site-content/update/:id",
      type: "edit",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/site-content/list",
      },
      api: {
        route: "/site-content",
        show: {
          route: "/site-content",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-12",
        },
        {
          key: "language",
          label: "language",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "de",
              key: "de",
            },
            {
              name: "en",
              key: "en",
            },
            {
              name: "ru",
              key: "ru",
            },
          ],
        },
        {
          key: "page",
          label: "page",
          col: "col-md-6",
          type: "select",
          options: [
            {
              name: "about-us",
              key: "about-us",
            },
            {
              name: "policy",
              key: "policy",
            },
          ],
        },
        {
          label: "content",
          key: "content",
          col: "col-md-12",
          type: "editor",
        },
      ],
    },
  ],
};
const reportType = {
  label: "Reports Type",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: true,
  options: [
    {
      route: "/report-type/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/report-type",
      },
      actions: [
        {
          type: "show",
          link: "/report-type/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/report-type",
        },
      ],
      header: [
        {
          label: "title",
          key: "title",
        },
        {
          label: "link",
          key: "link",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/report-type/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/report-type",
      },
      box: {
        route: "/report-type/list",
        title: "list",
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-12",
        },
        {
          type: "textarea",
          key: "desc",
          label: "desc",
          col: "col-md-12",
          noNeed: true,
        },
        {
          key: "type",
          label: "type",
          col: "col-md-4",
          type: "select",
          options: [
            {
              name: "item",
              key: "item",
            },
            {
              name: "user",
              key: "user",
            },
          ],
        },
      ],
    },
    {
      route: "/report-type/update/:id",
      type: "edit",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/report-type/list",
      },
      api: {
        route: "/report-type",
        show: {
          route: "/report-type",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-12",
        },
        {
          type: "textarea",
          key: "desc",
          label: "desc",
          col: "col-md-12",
          noNeed: true,
        },
        {
          key: "type",
          label: "type",
          col: "col-md-4",
          type: "select",
          options: [
            {
              name: "item",
              key: "item",
            },
            {
              name: "user",
              key: "user",
            },
          ],
        },
      ],
    },
  ],
};
const Reports = {
  label: "Reports",
  route: "/reports/list",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: false,
  options: [
    {
      route: "/reports/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/reports",
      },
      actions: [
        {
          type: "show",
          link: "/reports/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/reports",
        },
      ],
      header: [
        {
          type: "nested",
          objectKey: "user",
          key: "lastName",
          label: "user name",
        },
        {
          type: "nested",
          objectKey: "user",
          key: "email",
          label: "user email",
        },
        {
          label: "itemType",
          key: "itemType",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/reports/update/:id",
      type: "show",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/reports/list",
      },
      api: {
        route: "/reports",
        show: {
          route: "/reports",
          key: "site-content_id",
        },
      },
      form: [
        {
          key: "itemType",
          label: "itemType",
          col: "col-md-12",
        },
        {
          label: "description",
          key: "description",
          type: "textarea",
          col: "col-md-12",
        },
        {
          type: "link",
          label: "user Profile",
          key: "user",
        },
        {
          type: "item_link",
          label: "item details",
          key: "itemID",
        },
      ],
    },
  ],
};
const reportChat = {
  label: "Report Chat",
  route: "/report-messages/list",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: false,
  options: [
    {
      route: "/report-messages/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/report-messages",
      },
      actions: [
        {
          type: "show",
          link: "/report-messages/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/report-messages",
        },
      ],
      header: [
        {
          type: "nested",
          objectKey: "user",
          key: "lastName",
          label: "user name",
        },
        {
          type: "nested",
          objectKey: "user",
          key: "email",
          label: "user email",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/report-messages/update/:id",
      type: "show",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/report-messages/list",
      },
      api: {
        route: "/report-messages",
        show: {
          route: "/report-messages",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "description",
          key: "description",
          type: "textarea",
          col: "col-md-12",
        },
        {
          type: "chat_link",
          label: "show chat",
          key: "message",
        },
      ],
    },
  ],
};
const faq = {
  label: "FAQ",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: true,
  options: [
    {
      route: "/faq/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/faq",
      },
      box: {
        route: "/faq/list",
        title: "list",
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-8",
        },
        {
          key: "lang",
          label: "language",
          col: "col-md-4",
          type: "select",
          options: [
            {
              name: "de",
              key: "de",
            },
            {
              name: "en",
              key: "en",
            },
            {
              name: "ru",
              key: "ru",
            },
          ],
        },
        {
          label: "content",
          key: "content",
          col: "col-md-12",
          type: "editor",
        },
      ],
    },
    {
      route: "/faq/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/faq",
      },
      actions: [
        {
          type: "show",
          link: "/faq/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/faq",
        },
      ],
      header: [
        {
          label: "title",
          key: "title",
        },
        {
          label: "language",
          key: "lang",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/faq/update/:id",
      type: "edit",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/faq/list",
      },
      api: {
        route: "/faq",
        show: {
          route: "/faq",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-8",
        },
        {
          key: "lang",
          label: "language",
          col: "col-md-4",
          type: "select",
          options: [
            {
              name: "de",
              key: "de",
            },
            {
              name: "en",
              key: "en",
            },
            {
              name: "ru",
              key: "ru",
            },
          ],
        },
        {
          label: "content",
          key: "content",
          col: "col-md-12",
          type: "editor",
        },
      ],
    },
  ],
};
const ads = {
  label: "ADS",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: true,
  options: [
    {
      route: "/ads/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/ads",
      },
      box: {
        route: "/ads/list",
        title: "list",
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-12",
        },
        {
          label: "link",
          key: "link",
          col: "col-md-6",
        },
        {
          key: "favorite",
          noNeed: true,
          label: "favorite",
          col: "col-md-4",
          type: "select",
          options: [
            {
              name: "true",
              key: "true",
            },
            {
              name: "false",
              key: "false",
            },
          ],
        },
        {
          key: "image",
          label: "images",
          type: "images",
        },
      ],
    },
    {
      route: "/ads/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/ads",
      },
      actions: [
        {
          type: "show",
          link: "/ads/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/ads",
        },
      ],
      header: [
        {
          label: "title",
          key: "title",
        },
        {
          label: "link",
          key: "link",
        },
        {
          type: "enum",
          label: "favorite",
          enum: {
            true: "true",
            false: "false",
          },
          key: "favorite",
          col: "col-md-6",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/ads/update/:id",
      type: "edit",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/ads/list",
      },
      api: {
        route: "/ads",
        show: {
          route: "/ads",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-12",
        },
        {
          label: "link",
          key: "link",
          col: "col-md-6",
        },
        {
          key: "favorite",
          noNeed: true,
          label: "favorite",
          col: "col-md-4",
          type: "select",
          options: [
            {
              name: "true",
              key: "true",
            },
            {
              name: "false",
              key: "false",
            },
          ],
        },
        {
          key: "image",
          label: "images",
          type: "images",
        },
      ],
    },
  ],
};
const Reviews = {
  label: "Reviews",
  route: "/reviews/list",
  access: ["admin", "super-admin"],
  nested: false,
  icon: "fas fa-paperclip",
  options: [
    {
      route: "/reviews/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/reviews",
      },
      actions: [
        {
          type: "show",
          link: "/reviews/update",
          label: "show details",
        },
        {
          type: "delete",
          label: "delete",
          route: "/reviews",
        },
      ],
      header: [
        {
          objectKey: "user",
          type: "nested",
          label: "user last name",
          key: "lastName",
          col: "col-md-6",
        },
        {
          objectKey: "user",
          type: "nested",
          label: "user email",
          key: "email",
          col: "col-md-6",
        },
        {
          objectKey: "created_by",
          type: "nested",
          label: "rate by | name",
          key: "lastName",
          col: "col-md-6",
        },
        {
          objectKey: "created_by",
          type: "nested",
          label: "rate by | email",
          key: "email",
          col: "col-md-6",
        },
        {
          label: "rate",
          key: "rate",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/reviews/update/:id",
      type: "edit",
      show: false,
      label: "edit offer",
      box: {
        title: "list",
        route: "/reviews/list",
      },
      api: {
        route: "/reviews",
        show: {
          route: "/reviews",
          key: "review_id",
        },
      },
      form: [
        {
          key: "rate",
          noNeed: true,
          label: "rate",
          col: "col-md-12",
          type: "select",
          options: [
            {
              name: "1",
              key: "1",
            },
            {
              name: "2",
              key: "2",
            },
            {
              name: "3",
              key: "3",
            },
            {
              name: "4",
              key: "4",
            },
            {
              name: "5",
              key: "5",
            },
          ],
        },
        {
          label: "description",
          key: "desc",
          type: "textarea",
          col: "col-md-12",
        },
        {
          type: "link",
          label: "rated Profile",
          key: "user",
        },
        {
          type: "link",
          label: "rater Profile",
          key: "created_by",
        },
      ],
    },
  ],
};
const contactUs = {
  label: "contactUs",
  access: ["admin", "super-admin"],
  icon: "fas fa-user-edit",
  route: "/contact-us",
  nested: false,
  options: [
    {
      route: "/contact-us",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/contact-us",
      },
      actions: [
        {
          type: "show",
          link: "/contact-us",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/contact-us",
        },
      ],
      header: [
        {
          label: "email",
          key: "email",
        },
        {
          label: "title",
          key: "title",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/contact-us/:id",
      type: "show",
      show: false,
      label: "show details",
      box: {
        title: "list",
        route: "/contact-us",
      },
      api: {
        route: "/contact-us",
        show: {
          route: "/contact-us",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-6",
        },
        {
          label: "email",
          key: "email",
          col: "col-md-6",
        },
        {
          label: "description",
          key: "description",
          col: "col-md-12",
          type: "textarea",
        },
        {
          key: "attachments",
          label: "attachments",
          type: "attachments",
        },
      ],
    },
  ],
};
const Currency = {
  label: "Currency",
  icon: "fas fa-cogs",
  access: ["admin", "super-admin"],
  nested: true,
  options: [
    {
      route: "/currency/create",
      type: "form",
      show: true,
      label: "create",
      api: {
        route: "/price-unit",
      },
      box: {
        route: "/currency/list",
        title: "list",
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-6",
        },
        {
          label: "icon",
          key: "icon",
          col: "col-md-6",
        },
      ],
    },
    {
      route: "/currency/list",
      type: "table",
      show: true,
      label: "list",
      api: {
        route: "/price-unit",
      },
      actions: [
        {
          type: "show",
          link: "/currency/update",
          label: "show profile",
        },
        {
          type: "delete",
          label: "delete",
          route: "/price-unit",
        },
      ],
      header: [
        {
          label: "title",
          key: "title",
        },
        {
          type: "date",
          label: "createdAt",
          key: "createdAt",
        },
        {
          type: "date",
          label: "updatedAt",
          key: "updatedAt",
        },
      ],
    },
    {
      route: "/currency/update/:id",
      type: "edit",
      show: false,
      label: "edit admin",
      box: {
        title: "list",
        route: "/currency/list",
      },
      api: {
        route: "/price-unit",
        show: {
          route: "/price-unit",
          key: "site-content_id",
        },
      },
      form: [
        {
          label: "title",
          key: "title",
          col: "col-md-6",
        },
        {
          label: "icon",
          key: "icon",
          col: "col-md-6",
        },
      ],
    },
  ],
};
export const stracture = {
  pages: [
    dashboard,
    admins,
    users,
    requests,
    offers,
    Reviews,
    offersPic,
    reportType,
    Reports,
    reportChat,
    siteContent,
    faq,
    ads,
    Currency,
    contactUs,
    profile,
  ],
};
const Panel = () => {
  const renderRoute = (option) => {
    switch (option.type) {
      case "form":
        return (
          <Route
            path={option.route}
            element={
              <Layout stracture={stracture}>
                <Form options={option} />
              </Layout>
            }
          />
        );

      case "edit":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Form options={option} edit={true} />
              </Layout>
            }
          />
        );
      case "show":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Form options={option} edit={true} needButton={false} />
              </Layout>
            }
          />
        );
      case "table":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Table options={option} />
              </Layout>
            }
          />
        );
      case "dashboard":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        );
      case "ticketing":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Support />
              </Layout>
            }
          />
        );
      case "surface":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Surfaces option={option} />
              </Layout>
            }
          />
        );
      case "test":
        return (
          <Route
            path="/test"
            element={
              <Layout>
                <FormV1 />
              </Layout>
            }
          />
        );

      default:
        break;
    }
  };
  const {
    state: { user },
  } = useMainContext();
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        {stracture &&
          stracture?.pages.map((page) => {
            return page?.options.map((option) => {
              if (option) {
                return renderRoute(option);
              }
            });
          })}
      </Routes>
    </>
  );
};

export default Panel;
