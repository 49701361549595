import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";

export const useProfile = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ get: true, send: false });
  const {
    state: { user },
    post,
    get,
    put,
  } = useMainContext();
  const getData = async () => {
    setLoading({ get: true });
    const { data } = await get(`/islogin`);
    setData(data);
    setLoading({ get: false });
  };
  useEffect(() => {
    getData();
  }, []);
  const sendData = async () => {
    setLoading({ send: "edit_profile" });
    await post(`/profile`, data, true);
    setLoading({ send: false });
  };
  const changePassword = async () => {
    setLoading({ send: "password" });
    await post(`/profile/password`, data, true);
    setLoading({ send: false });
  };

  return {
    setData,
    data,
    loading,
    sendData,
    changePassword,
  };
};
