import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../Context";

const Navbar = () => {
  const { state } = useMainContext();
  return (
    <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand ">
      <button
        className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation">
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </button>
      <Link
        to="/profile"
        className="navbar-brand me-1 me-sm-3"
        href="index.html">
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={require("../../logo-light.svg").default}
            alt
            style={{ width: "60px", objectFit: "contain" }}
          />
          <span className="font-sans-serif" style={{fontSize:'15px'}}>Kalli Management Panel</span>
        </div>
      </Link>
    </nav>
  );
};

export default Navbar;
