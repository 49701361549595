import React from "react";
import { useProfile } from "./Hooks";

const Profile = () => {
  const {
    sendData,
    data,
    loading,
    setData,
    changePassword,
  } = useProfile();

  if (loading.get) {
    return (
      <div className="hv-100 d-flex align-items-center justify-content-center">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"></span>
      </div>
    );
  }
  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-lg-12 pe-lg-2">
          <div className="card mb-3">
            <div className="card-header">
              <h5 className="mb-0">Edit Profile</h5>
            </div>
            <div className="card-body bg-light">
              <div className="row g-3">
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="first-name">
                    Full Name
                  </label>
                  <input
                    className="form-control"
                    id="first-name"
                    type="text"
                    value={data.name}
                    onChange={({ target: { value } }) => {
                      setData({ ...data, name: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="email2">
                    email
                  </label>
                  <input
                    className="form-control"
                    id="email2"
                    type="text"
                    value={data.email}
                    disabled
                  />
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <button
                    onClick={() => {
                      sendData("edit_profile");
                    }}
                    className="btn btn-primary"
                    type="submit"
                    loading={loading.send}>
                    {loading.send == "edit_profile" ? (
                      <>
                        <span
                          class="spinner-border spinner-border"
                          role="status"
                          aria-hidden="true"></span>
                      </>
                    ) : (
                      <>update</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">change password</h5>
            </div>
            <div className="card-body bg-light">
              <div className="row g-3">
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="first-name">
                    new password
                  </label>
                  <input
                    className="form-control"
                    id="first-name"
                    type="password"
                    onChange={({ target: { value } }) => {
                      setData({ ...data, confirm: value });
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label className="form-label" htmlFor="email2">
                    confirm new password
                  </label>
                  <input
                    className="form-control"
                    id="email2"
                    type="password"
                    onChange={({ target: { value } }) => {
                      setData({ ...data, password: value });
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <button
                    onClick={() => {
                      changePassword("change_password");
                    }}
                    className="btn btn-primary"
                    type="submit">
                    {loading.send == "change_password" ? (
                      <>
                        <span
                          class="spinner-border spinner-border"
                          role="status"
                          aria-hidden="true"></span>
                      </>
                    ) : (
                      <>update password</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
