import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMainContext } from "../../../Context";
import { useSearchObject } from "../../../Hooks";

export const useDashboard = ({ api }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { get } = useMainContext();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    const { data } = await get(`${api?.route}`);
    setData(data);
    setLoading(false);
  };

  return {
    loading,
    data,
  };
};
