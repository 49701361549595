import React from "react";
import { useLocation } from "react-router-dom";
import OfferDetails from "./OfferDetails";
import RequestDetails from "./RequestDetails";
import Profile from "../Profile";
import Chat from "./Chat";

const Surfaces = ({ option }) => {
  switch (option.surfaceType) {
    case "offerData":
      return <OfferDetails option={option} />;
    case "requestData":
      return <RequestDetails option={option} />;
    case "chat":
      return <Chat option={option} />;
    case "profile":
      return <Profile />;
    default:
      break;
  }
};

export default Surfaces;
