import React from "react";
import { useDetails } from "./Hooks";
import moment from "jalali-moment";
import Table from "../Table";

const OfferDetails = ({ option }) => {
  const {
    data,
    loading,
  } = useDetails({api:option?.api});
  if (loading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"></span>
      </div>
    );
  }
  return (
    <div className="content">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8  mb-4 mb-lg-0">
              <h3 className="mb-3 ">offer details</h3>
              <div className="row">
                <div className="col-md-6 d-flex ">
                  <h6> fromCountry : </h6>
                  <h6 className="mr-2"> {data?.data?.fromCountry} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> fromCity : </h6>
                  <h6 className="mr-2"> {data?.data?.fromCity} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> destinationCountry : </h6>
                  <h6 className="mr-2"> {data?.data?.destinationCountry} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> destinationCity : </h6>
                  <h6 className="mr-2"> {data?.data?.destinationCity} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> packageWeight : </h6>
                  <h6 className="mr-2"> {data?.data?.packageWeight} kg </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> AcceptPackageType : </h6>
                  <h6 className="mr-2"> {data?.data?.AcceptPackageType} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> price : </h6>
                  <h6 className="mr-2"> {data?.data?.price} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> expireAt : </h6>
                  <h6 className="mr-2">
                    {moment
                      .from(data?.data?.expireAt, "en", "YYYY/MM/DD")
                      .format("YYYY/MM/DD")}{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8  mb-4 mb-lg-0">
              <h3 className="mb-3 ">user information</h3>
              <div className="row">
                <div className="col-md-6 d-flex ">
                  <h6> name : </h6>
                  <h6 className="mr-2"> {data?.data?.user?.name} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> lastName : </h6>
                  <h6 className="mr-2"> {data?.data?.user?.lastName} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> email : </h6>
                  <h6 className="mr-2"> {data?.data?.user?.email} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> mobile : </h6>
                  <h6 className="mr-2"> {data?.data?.user?.mobile} </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> pay method : </h6>
                  <h6 className="mr-2"> {data?.data?.user?.paymentMethods}</h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> zipCode : </h6>
                  <h6 className="mr-2">
                    {" "}
                    {data?.data?.user?.location?.zipCode}{" "}
                  </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> country : </h6>
                  <h6 className="mr-2">
                    {" "}
                    {data?.data?.user?.location?.country}{" "}
                  </h6>
                </div>
                <div className="col-md-6 d-flex ">
                  <h6> city : </h6>
                  <h6 className="mr-2"> {data?.data?.user?.location?.city} </h6>
                </div>
                <div className="col-md-12 d-flex ">
                  <h6> address : </h6>
                  <h6 className="mr-2">
                    {" "}
                    {data?.data?.user?.location?.address}{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <Table
          options={{
            dashboard: {
              data: data?.conversations.map((e) => {
                return {
                  ...e.suggestedUserID,
                  conversationsId:e._id,
                  agreement:
                    e._id == data?.data?.agreementUser ? "true" : "false",
                };
              }),
            },
            header: [
              {
                label: "first name",
                key: "name",
              },
              {
                label: "last name",
                key: "lastName",
              },
              {
                label: "email",
                key: "email",
              },
              {
                label: "agreement status",
                key: "agreement",
              },
            ],
            actions: [
              {
                type: "show",
                link: "/user/update",
                label: "show profile",
              },
              {
                keyGo:'conversationsId',
                type: "showChat",
                link: "/conversations/chat",
                label: "show chat",
              },
            ],
            api: {},
            label: "conversations",
          }}
        />
      </div>
    </div>
  );
};

export default OfferDetails;
