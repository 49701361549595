import React, { useEffect, useState } from "react";
import { countries } from "../../../../Utils/countries";
import { renderCity, renderCountry } from "../../../../Utils/functions";

function Index({
  set = () => { },
  data = {},
  value,
  setForm = () => { },
  form = {},
  element = {},
}) {
  const [country, setCountry] = useState(false);
  const setDataCountry = count => {
    setCountry(count.iso2);
    setForm({
      ...form,
      [element.subKey ? element.subKey + "Country" : "country"]: count.iso2,
      countryCode: count.code,
    });
  };
  return (
    <>
      <div className="col-md-6 mt-3">
        <label htmlFor="" className="d-block">
          {element.subLabel ? element.subLabel + " country" : "country"}
        </label>
        {
          Object.keys(data).length > 0 ? (<span style={{ fontSize: '11px' }} >last selected : {renderCountry(value(element.subKey ? element.subKey + "Country" : "country"))?.name}</span>) : <></>
        }
        <select
          onChange={({ target: { value } }) =>
            setDataCountry(renderCountry(value))
          }
          className="form-control"
          id={Math.random()}
        >
          <option value="">select country</option>
          {countries.map((element, key) => (
            <option key={key} value={element.iso2} >
              {element.name}
            </option>
          ))}
        </select>
        {data && data.country && (
          <div className="mt-1">
            <h6>last country : {`${data.country}`}</h6>
          </div>
        )}
      </div>
      <div className="col-md-6 mt-3">
        <label htmlFor="" className="d-block">
          {element.subLabel ? element.subLabel + " city" : "city"}
        </label>
        {
          Object.keys(data).length > 0 ? (<span style={{ fontSize: '11px' }} >last selected : {value(element.subKey ? element.subKey + "City" : "city")}</span>) : <></>
        }
        <select
          disabled={!country}
          className="form-control mb-1"
          id={Math.random()}
          onChange={({ target: { value } }) =>
            set(element.subKey ? element.subKey + "City" : "city", value)
          }
        >
          <option value="">select city</option>
          {renderCity(country).map((element, key) => (
            <option key={key} value={element.value} >
              {element.label}
            </option>
          ))}
        </select>
        {data && data.city && (
          <div className="mt-1">
            <h6>last city : {`${data.city}`}</h6>
          </div>
        )}
      </div>
    </>
  );
}

export default Index;
