import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import CheckExist from "../../../CheckExist";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
// import { getOrientation } from "get-orientation/browser";
const ZOOM_STEP = 0.1;
const ROTATION_STEP_DEG = 10;
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};
const CropperGoddess = ({ form, setForm, element }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setForm({ ...form, [element.key]: croppedImage });
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);
  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };
  return (
    <CheckExist
      state={
        element.exist ? form[element.exist.key] == element.exist.value : true
      }
    >
      <div className="my-2  ">
        <label>{element.label}</label>
        {imageSrc ? (
          <>
            {croppedImage ? (
              <>
                <div className="" style={{}}>
                  <img
                    style={{ height: "400px", objectFit: "contain" }}
                    src={URL.createObjectURL(croppedImage)}
                  />
                </div>
                <div className="mt-2 " style={{ bottom: "-50px" }}>
                  <button
                    className="btn btn-danger ml-px-12"
                    onClick={() => {
                      setCroppedImage(null);
                      setForm({ ...form, [element.key]: null });
                    }}
                  >
                    بازگشت
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="position-relative " style={{ height: "400px" }}>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={element.aspect}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="mt-2 " style={{ bottom: "-50px" }}>
                  <button
                    className="btn btn-danger ml-px-12"
                    onClick={() => {
                      setImageSrc("");
                    }}
                  >
                    بازگشت
                  </button>
                  <button
                    className="btn   btn-success"
                    onClick={showCroppedImage}
                  >
                    ثبت طرح
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center border py-8 border-dashed">
            <input
              type={"file"}
              className="d-none"
              onChange={onFileChange}
              id={"imageSrc"}
              accept="image/*"
            />
            <label className="mb-0" htmlFor="imageSrc">
              آپلود تصویر <i className="fas fa-upload mx-2"></i>
            </label>
          </div>
        )}
      </div>
    </CheckExist>
  );
};

export default CropperGoddess;
function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
