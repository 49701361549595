import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../../../Context";

const CardTable = ({ data }) => {
  const { state:{user:{user}} } = useMainContext();
  return (
    <div className="col-12">
      <div className="card bg-transparent-50 overflow-hidden">
        <div className="card-header position-relative">
          <div
            className="bg-holder d-none d-md-block bg-card z-index-1"
            style={{
              backgroundImage: "url(/assets/img/logo-light.svg)",
              backgroundSize: 230,
              zIndex: -1,
            }}
          />
          {/*/.bg-holder*/}
          <div className="position-relative z-index-2">
            <div>
              <h3 className="text-primary mb-1">
                Welcome dear {user.name} !
              </h3>
            </div>
            <div className="d-flex py-3">
            <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">total user</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.total_users}
                </h4>
              </div>
              <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">total offer</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.total_offers}
                </h4>
              </div>
              <div className="pe-3">
                <p className="text-600 fs--1 fw-medium">total request</p>
                <h4 className="text-800 mb-0 text-center">
                  {data?.total_requests}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTable;
