import React from "react";
import { useChat } from "./Hooks";
import { timeSince } from "../../Utils/functions";
import { MdOutlineVerticalAlignTop } from "react-icons/md";
import { Link } from "react-router-dom";
const Chat = () => {
  const { chat, loading, loadMore, loadingBtn, owner } = useChat();
  if (loading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  }
  console.log(chat);
  return (
    <div className="card card-chat overflow-hidden">
      <div className="card-body d-flex p-0 h-100">
        <div className="tab-content card-chat-content">
          <div className="tab-pane card-chat-pane active">
            <div className="chat-content-scroll-area scrollbar">
              {chat.hasNextPage ? (
                <>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-danger mt-2"
                      disabled={loadingBtn}
                      onClick={() => {
                        loadMore();
                      }}
                    >
                      {loadingBtn ? (
                        <>Loading...</>
                      ) : (
                        <>
                          <MdOutlineVerticalAlignTop />
                        </>
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}

              {chat?.docs?.map((item, key) => {
                if (owner === item.author._id) {
                  return (
                    <div className="d-flex p-3">
                      <div className="flex-1">
                        <div className="w-xxl-75">
                          <h6 class="mb-0">
                            <Link
                              class="text-decoration-none stretched-link text-700"
                              to={"/user/update/" + item?.author?._id}
                            >
                              {`${item?.author?.name} ${item?.author?.lastName}`}
                            </Link>
                          </h6>
                          <div className="hover-actions-trigger d-flex align-items-center">
                            <div className="chat-message bg-200 p-2 rounded-2">
                              {item.body}
                            </div>
                          </div>
                          <div className="text-400 fs--2">
                            <span>{timeSince(item.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="d-flex p-3">
                      <div className="flex-1 d-flex justify-content-end">
                        <div className="w-100 w-xxl-75">
                          <h6 class="mb-0 ">
                            <p
                              class="text-decoration-none stretched-link text-700 text-end"
                              href="../pages/user/profile.html"
                            >
                              {`${item?.author?.name} ${item?.author?.lastName}`}
                            </p>
                          </h6>
                          <div className="hover-actions-trigger d-flex flex-end-center">
                            <div className="bg-primary text-white p-2 rounded-2 chat-message light">
                              {item.body}
                            </div>
                          </div>
                          <div className="text-400 fs--2 text-end">
                            {timeSince(item.createdAt)}
                            <svg
                              className="svg-inline--fa fa-check fa-w-16 ms-2"
                              aria-hidden="true"
                              focusable="false"
                              chat-prefix="fas"
                              chat-icon="check"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              chat-fa-i2svg
                            >
                              <path
                                fill="currentColor"
                                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                              />
                            </svg>
                            {/* <span class="fas fa-check ms-2"></span> Font Awesome fontawesome.com */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
