import React from "react";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { Calendar } from "react-multi-date-picker";
import { ConvertDate } from "../../../../Utils/functions";
import moment from "jalali-moment";
const CustomDatePicker = ({ element, form, setForm, single = false }) => {
  console.log(form);
  if (element.single) {
    return (
      <div className="my-3">
        <label>{element.label}</label>
        <div className="d-flex justify-content-center">
          <Calendar
            arrow={<div>kir</div>}
            range={false}
            onChange={(data) => {
              if (data) {
                setForm({
                  ...form,
                  [element.key]: ConvertDate(
                    `${data.year}/${data.month.number}/${data.day}`
                  ),
                });
              }
            }}
            calendar={gregorian}
            locale={gregorian_en}
            // value={form[element.key]}
          />
        </div>
        <div>
          <p className="text-center">
            last date selected :{" "}
            {moment
              .from(form[element.key], "en", "YYYY/MM/DD")
              .format("YYYY/MM/DD")}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="my-3">
      <label>{element.label}</label>
      <Calendar
        range={true}
        onChange={(data) => {
          if (data.length > 1) {
            setForm({
              ...form,
              [element.key[0]]: ConvertDate(
                `${data[0].year}/${data[0].month.number}/${data[0].day}`
              ),
              [element.key[1]]: ConvertDate(
                `${data[1].year}/${data[1].month.number}/${data[1].day}`
              ),
            });
          }
        }}
        calendar={gregorian}
        locale={gregorian_en}
      />
    </div>
  );
};

export default CustomDatePicker;
