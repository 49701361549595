import React from "react";
import { API } from "../../../../Server";

const ShowImages = ({ form }) => {
  console.log("dw", form);
  return (
    <div className="d-flex gap-3 my-2">
      {form?.imageAddress?.map(ele => {
        return (
          <div>
            <img src={API + ele} alt="" className="w-100" />
          </div>
        );
      })}
    </div>
  );
};

export default ShowImages;
