import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { useMainContext } from "../../../../Context";

const ArrayComponent = ({ element, form, setForm, data, onClose }) => {
  const [word, setWord] = useState("");
  const { post, del } = useMainContext();
  const [loading, setloading] = useState({ get: false, post: false });
  const addWordHandler = async () => {
    setloading({ post: true });
    await post("/manager/ip-panel-usernames", {
      ip_panel_username: word,
      user_id: data.id,
    });
    setloading({ post: false });
    onClose();
  };
  const deleteWord = async wo => {
    setloading({ post: true });
    await del("/manager/ip-panel-usernames/" + wo.id, {});
    setloading({ post: false });
    onClose();
  };
  const renderkeys = () => {
    if (Array.isArray(data.ippanelusernames)) {
      if (data.ippanelusernames.length != 0) {
        return data.ippanelusernames.map(item => {
          return (
            <button
              className="btn btn-outline-light m-2 text-dark"
              onClick={() => {
                deleteWord(item);
              }}
            >
              <span>{item.ippanel_username}</span>
              <span style={{ marginRight: "10px" }}>
                <AiOutlineClose />
              </span>
            </button>
          );
        });
      } else {
        return (
          <h6 style={{ textAlign: "center", fontWeight: "400" }}>
            موردی یافت نشد
          </h6>
        );
      }
    } else {
      return (
        <h6 style={{ textAlign: "center", fontWeight: "400" }}>
          موردی یافت نشد
        </h6>
      );
    }
  };
  return (
    <div className="col-12 my-2">
      <div>
        <label style={{ fontWeight: "400" }}>نام های کاربری آی پی پنل</label>
        <div className="d-flex mb-2">
          <input
            className="form-control"
            onChange={({ target: { value } }) => {
              setWord(value);
            }}
            value={word}
            onKeyDown={e => {
              if (e.key == "Enter") {
                addWordHandler();
              }
            }}
          />
          <button
            disabled={loading.post}
            className="btn btn-primary mr-2"
            onClick={addWordHandler}
          >
            {loading.post ? <>loading ...</> : <>ثبت</>}
          </button>
        </div>
      </div>
      <div className="border rounded p-2">{renderkeys()}</div>
    </div>
  );
};

export default ArrayComponent;
