import React from "react";
import { isJson } from "../../../../Utils/functions";

const JsonFormatter = ({
  element,
  form,
  setForm,
  handleChange,
  handleValue,
}) => {
  const parsJson = () => {
    if (isJson(form[element.key])) {
      return (
        <p className="text-success text-right" style={{ textAlign: "right" }}>
          {" "}
          معتبر است json
        </p>
      );
    } else {
      return <p className="text-danger"> "json اشتباه میباشد"</p>;
    }
  };
  return (
    <div className="form-group">
      <label htmlFor="exampleTextarea1">{element.label}</label>
      <textarea
        onChange={({ target: { value } }) => {
          handleChange(element.key, value, element.nested);
        }}
        value={handleValue(element.key, element.nested)}
        className="form-control"
        id="exampleTextarea1"
        placeholder={element.label}
        rows={14}
        style={{ minHeight: "10rem" }}
        dir="ltr"
      />
      <pre className="border p-2 h-auto my-2 rounded">{parsJson()}</pre>
    </div>
  );
};

export default JsonFormatter;
