import { useState } from "react";
import { useSearchObject } from "../../../Hooks";
import {
  cutText,
  renderPrice,
  renderStatus,
  timeSince,
  ToDateJalali,
  ToTimeJalali,
} from "../../../Utils/functions";
import { BiShow } from "react-icons/bi";
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsChat,
  BsCheckLg,
  BsReply,
  BsSignpostSplitFill,
  BsToggles,
} from "react-icons/bs";
import { FaPhoenixFramework, FaTrash } from "react-icons/fa";
import { MdModeEditOutline, MdPayment, MdTimer } from "react-icons/md";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMainContext } from "../../../Context";
import { AiOutlinePlus } from "react-icons/ai";
import { FiGift } from "react-icons/fi";
import { API_SERVER } from "../../../Server";
import { IoIosSwitch } from "react-icons/io";
import moment from "jalali-moment";
const countries = require("../../../Utils/countries.json");
export const useTable = ({
  route = "",
  inint = {},
  selectAble = {},
  filters = [],
  dashboard = {},
  sortInint = state => state,
}) => {
  const [data, setData] = useState({});
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [LoadingDelete, setLoadingDelete] = useState(false);
  const [LoadingExcel, setLoadingExcel] = useState(false);
  const [areUSure, setAreUSure] = useState({
    id: null,
    status: false,
    data: {},
    type: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { findNestedObj } = useSearchObject();
  const { post, get, state, del, put } = useMainContext();
  console.log(data);

  const getData = async props => {
    setLoading(true);
    const { data } = await get(route + `?${searchParams}`, {
      ...inint,
      ...props,
      ...createObjFilter(),
    });
    if (sortInint) {
      setData(sortInint(data || { data: [] }));
    } else {
      setData(data || { data: [] });
    }

    setLoading(false);
  };
  const createObjFilter = () => {
    let obj = {};
    filters.map(element => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    return obj;
  };
  const changeObj = (key, value) => {
    let obj = {};
    filters.map(element => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    setSearchParams({ ...obj, [key]: value });
  };
  const filterDelete = key => {
    let obj = {};
    filters.map(element => {
      if (element.key !== key)
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
    });
    setSearchParams({ ...obj });
  };
  const deleteRow = async (data, init = {}) => {
    setLoadingDelete(init.operation || true);
    if (data.type != "delete") {
      if (init.put) {
        await put(
          `${data.action.route}/${data.id}`,
          { [data?.action?.key]: data.id, ...init },
          true
        );
      } else {
        await post(
          `${data.action.route}`,
          { [data?.action?.key]: data.id, ...init },
          true
        );
      }
    } else {
      await del(`${data.action.route}/${data.id}`, true);
    }
    setLoadingDelete(false);
    setSelected([]);
    setAreUSure({ status: false });
  };
  const navigate = useNavigate();

  const renderCloumn = (row, col) => {
    switch (col.type) {
      case "date":
        return row && timeSince(row[col.key]);
      case "long":
        return row && cutText(row[col.key]);
      case "status":
        return row && row[col.key] ? "active" : "deative";
      case "isBlocked":
        return row && row[col.key] === true ? "block" : "active";
      case "enum":
        return row && row[col.key] && col.enum[row[col.key]]
          ? col.enum[row[col.key]]
          : col.enum.default;
      case "zero":
        return row && row[col.key] ? col.enum[0] : col.enum[1];

      case "nested":
        return (
          row &&
          findNestedObj(row[col.objectKey], col.key) &&
          findNestedObj(row[col.objectKey], col.key)[col.key]
        );
      case "exact-date":
        return ToTimeJalali(row[col.key]);
      case "multiLan":
        return ToDateJalali(row[col.key]);
      case "link":
        return (
          <a
            target="_blank"
            href={`https://api.ahantaraghi.com/${row[col.key]}`}
          >
            <span className="fas fa-file-excel"></span>
          </a>
        );
      case "static":
        return col.value;
      case "full_name":
        if (row?.accountable?.account_type == "personal") {
          return row?.accountable?.full_name;
        } else {
          return row?.accountable?.company_name;
        }
      case "fullnameSeller":
        if (row.seller) {
          return `${row?.seller?.first_name} ${row?.seller?.last_name}`;
        } else {
          return `بدون کارشناس`;
        }
      case "fullname":
        if (row?.first_name && row?.last_name) {
          return `${row?.first_name} ${row?.last_name}`;
        } else {
          return `ثبت نشده`;
        }
      case "rel":
        if (row.rel) {
          return renderStatus(row?.rel?.status)["label"];
        } else {
          return "-----";
        }
      case "kind":
        if (row?.accountable?.account_type == "personal") {
          return "حقیقی";
        } else {
          return "حقوقی";
        }
      case "tax":
        return +row.price + +row.feature + +row.tax - +row.discount;
      case "time":
        if (row?.time && typeof row.time !== "object") {
          return moment(+row.time * 1000)
            .locale("fa")
            .format("HH:mm:ss - YYYY/MM/DD");
        }

        return moment().locale("fa").format("HH:mm:ss - YYYY/MM/DD");
      case "time2":
        if (row?.fileName) {
          return moment(
            +row?.fileName?.split("pay-")[1]?.split(".json")[1] * 1000
          )
            .locale("fa")
            .format("HH:mm:ss - YYYY/MM/DD");
        }
        return moment().locale("fa").format("HH:mm:ss - YYYY/MM/DD");
      case "company":
        if (row?.user?.accountable?.account_type === "legal") {
          return row?.user?.accountable?.company_name;
        } else {
          return row?.user?.accountable?.full_name;
        }
      case "price":
        if (row[col.key]) return renderPrice(row[col.key]);
        return;
      case "country":
        let res = {};
        Object.keys(countries).map(ele => {
          if (ele === row?.location?.country) {
            res = countries[ele];
          }
        });
        console.log("re", res);
        return res.name;
      case "countryFrom":
        let result = {};
        Object.keys(countries).map(ele => {
          if (ele === row[col.key]) {
            result = countries[ele];
          }
        });
        console.log("re", result);
        return result.name;
      case "statusAgree":
        if (row[col.key]) {
          return (
            <div className="d-flex align-items-center">
              <span class="dot bg-success"></span>
              <p className="text-success">Agreement</p>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center">
              <span class="dot bg-danger"></span>
              <p className="text-danger">Disagreement</p>
            </div>
          );
        }
        return <></>;
      case "array":
        if (row[col.key]?.length === 0) {
          return <>ثبت نشده</>;
        }
        if (row[col.key]) {
          return (
            <p>
              {row[col.key].map(item => (
                <span className="">{item.ippanel_username}, </span>
              ))}
            </p>
          );
        }
        break;
      default:
        return row && row[col.key];
    }
  };
  const renderAction = (action, row) => {
    let props = {
      style: {
        marginRight: "10px",
      },
      color: "#a1a5b7",
    };
    switch (action.type) {
      case "edit":
        return (
          <Link
            to="/"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
          >
            <MdModeEditOutline {...props} />
          </Link>
        );
      case "goNoFollow":
        let link = "";
        if (row.rel) {
          switch (row.product_id) {
            case 1:
              link = `https://thecartino.ir/c/${row.rel.key}`;
              break;
            case 2:
              link = `https://thecartino.ir/l/${row.rel.key}`;
              break;

            case 3:
              link = `https://thecartino.ir/f/${row.rel.key}`;

              break;
            default:
              break;
          }
        } else {
          link = false;
        }

        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              window.open(link, "_blank");
            }}
            disabled={!link}
          >
            <BsArrowLeftCircleFill {...props} />
          </button>
        );
      case "show":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={
              `${action.link}/${action.keyGo ? row[action.keyGo] : row._id}` ||
              "/"
            }
          >
            <BiShow {...props} />
          </Link>
        );
      case "showChat":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={
              `${action.link}/${action.keyGo ? row[action.keyGo] : row._id}` ||
              "/"
            }
          >
            <BsChat {...props} />
          </Link>
        );
      case "factor":
        return <Factor row={row} />;

      case "delete":
        if (action.access) {
          if (action.access.iagreementUserncludes(state.user.user.role)) {
            return (
              <button
                className=" p-0 m-0"
                data-toggle="tooltip"
                data-placement="top"
                title={action.label}
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => {
                  setAreUSure({
                    status: true,
                    id: row._id,
                    data: row,
                    type: "delete",
                    action,
                  });
                }}
              >
                <FaTrash {...props} />
              </button>
            );
          } else {
            return <></>;
          }
        } else {
          return (
            <button
              className=" p-0 m-0"
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              style={{ border: "none", backgroundColor: "transparent" }}
              onClick={() => {
                setAreUSure({
                  status: true,
                  id: row._id,
                  data: row,
                  type: "delete",
                  action,
                });
              }}
            >
              <FaTrash {...props} />
            </button>
          );
        }

      case "toggle":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "toggle",
              });
            }}
          >
            <BsToggles {...props} />
          </button>
        );
      case "toggleOrder":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "toggleOrder",
              });
            }}
          >
            <BsToggles {...props} />
          </button>
        );
      case "toggleAliOmid":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: {
                  ...row,
                  status: row.status == "new" ? "answered" : "new",
                },
                action,
                type: "toggleAliOmid",
              });
            }}
          >
            <BsToggles {...props} />
          </button>
        );
      case "gift":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "gift",
              });
            }}
          >
            <FiGift {...props} />
          </button>
        );
      case "select":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "select",
              });
            }}
          >
            <BsToggles {...props} />
          </button>
        );
      case "go":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={
              `${action.link}/${action.keyGo ? row[action.keyGo] : row._id}` ||
              "/"
            }
          >
            <BsArrowRightCircleFill {...props} />
          </Link>
        );
      case "payment":
        return <MdPayment {...props} />;
      case "time":
        return <MdTimer {...props} />;
      case "add":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "add",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <AiOutlinePlus {...props} />
          </button>
        );
      case "reply":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "reply",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <BsReply {...props} />{" "}
          </button>
        );
      case "report":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row._id,
                data: row,
                action,
                type: "report",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <AiOutlinePlus {...props} />
          </button>
        );
      case "aliOmid":
        return (
          <button
            className="btn btn-danger fs--1"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate(action.link || "/careers/records", { state: { row } });
            }}
          >
            {action.text || "مشاهده فرم های پرشده"}
          </button>
        );
      case "active":
        return (
          <>
            <button
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              onClick={() => {
                setAreUSure({
                  status: true,
                  id: row._id,
                  data: row,
                  action,
                  type: "active",
                });
              }}
              className=" p-0 m-0"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <IoIosSwitch {...props} />
            </button>
          </>
        );
      case "addFrame":
        return (
          <>
            <button
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              disabled={
                row.rel
                  ? row.rel.status == "receiving_info"
                    ? false
                    : true
                  : true
              }
              onClick={() => {
                navigate("/order/submitOrder/" + row._id);
              }}
              className=" p-0 m-0"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <FaPhoenixFramework {...props} />
            </button>
          </>
        );
      case "trackingPost":
        return (
          <>
            <button
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              disabled={
                row.rel
                  ? row.rel.status == "ready_to_send"
                    ? false
                    : true
                  : true
              }
              onClick={() => {
                setAreUSure({
                  status: true,
                  id: row._id,
                  data: row,
                  type: "trackingPost",
                  action,
                });
              }}
              className=" p-0 m-0"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <BsSignpostSplitFill {...props} />
            </button>
          </>
        );
      case "array":
        return (
          <>
            <button
              data-toggle="tooltip"
              data-placement="top"
              title={action.label}
              onClick={() => {
                setAreUSure({
                  status: true,
                  id: row._id,
                  data: row,
                  type: "array",
                  action,
                });
              }}
              className=" p-0 m-0"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              <BsSignpostSplitFill {...props} />
            </button>
          </>
        );
      case "country":
        console.log("row", row);
        return <></>;

      default:
        break;
    }
  };
  const isIn = id => {
    return selected.includes(id);
  };
  const toggle = id => {
    if (isIn(id)) {
      setSelected([...selected.filter(item => item !== id)]);
    } else {
      setSelected([...selected, id]);
    }
  };
  const toggleAll = () => {
    if (isSelectedAll()) {
      setSelected([]);
    } else {
      setSelected([...data.data.map(item => item._id)]);
    }
  };
  const isSelectedAll = () => {
    return selected.length == data?.data.length;
  };
  const length = () => {
    return selected.length;
  };

  return {
    renderCloumn,
    loading,
    getData,
    data,
    renderAction,
    areUSure,
    setAreUSure,
    LoadingDelete,
    deleteRow,
    isIn,
    toggle,
    toggleAll,
    isSelectedAll,
    length,
    selected,
    searchParams,
    setSearchParams,
    changeObj,
    filterDelete,
  };
};
const Factor = ({ row }) => {
  let props = {
    style: {
      marginRight: "10px",
    },
    color: "#a1a5b7",
  };
  const { get } = useMainContext();
  return (
    <a
      onClick={() => {
        window.open(`${API_SERVER}/manager/invoices/${row._id}?t=${row.hash}`);
      }}
    >
      <BiShow {...props} />
    </a>
  );
};
