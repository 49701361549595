import React, { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "change":
      return { ...state };

    default:
      break;
  }
};

const Test = () => {
  const [state, dispatch] = useReducer(reducer, {});
  return (
    <div>
      <input
        onChange={e => {
          dispatch({
            type: "change",
            payload: e,
          });
        }}
      />
    </div>
  );
};

export default Test;
