import React from "react";
import Table from "../Table";
import CardTable from "./Components/CardTable";
import { useDashboard } from "./Hooks";
import Clock from "./Components/Clock";

const Dashboard = () => {
  const { loading, data } = useDashboard({
    api: { route: "/dashboard" },
  });
  if (loading) {
    return <></>;
  }
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-9">
          <CardTable data={data.data} />
        </div>
        <div className="d-none d-md-flex  col-md-3">
          <Clock size={180} />
        </div>
      </div>
      <div className="row mt-3">
      <Table
          options={{
            dashboard: { data: data.data.last_users },
            header: [
              {
                label: "first name",
                key: "name",
              },
              {
                label: "last name",
                key: "lastName",
              },
              {
                label: "email",
                key: "email",
              },
              {
                type: "nested",
                objectKey: "location",
                label: "country",
                key: "country",
              },
              {
                type: "nested",
                objectKey: "location",
                label: "city",
                key: "city",
              },
            ],
            api: {},
            label: "last users",
          }}
        />
      </div>
    </div>
  );
};
export default Dashboard;
